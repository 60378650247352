
import React from 'react'
import { ClientLogoType } from '../../../@types'

import ClientFilterGroup from './../../molecules/ClientFilterGroup';
// mui
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';





const Header = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));



type PortfolioHeaderProps = {

}


export default function PortfolioHeader() {


    return(
        <Header> 

         <ClientFilterGroup />


        </Header>
    )
}
