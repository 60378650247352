
import React from 'react'
import { ClientLogoType } from '../../../@types'

import ClientLogo from './../../atoms/ClientLogo'
// mui
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2



type ClientLogoListProps ={ 
    clientLogoList: ClientLogoType[]
}


//5/12



export default function ClientLogoList({ clientLogoList } :ClientLogoListProps) {


    return(

        <Grid container spacing={2}>

            { clientLogoList.map((clientLogo) => 
              <Grid xs={2.2} key={clientLogo.id} >
               <ClientLogo {...clientLogo}/> 
              </Grid>
            ) }
        </Grid>


    )
}
