import * as React from "react"
import type { HeadFC } from "gatsby"

//
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

//
import ClientLogoList from './../components/molecules/ClientLogoList'
import PortfolioHeader from './../components/organisms/PortfolioHeader'
//
import InfiniteScroll from 'react-infinite-scroller';





const portfolioData = [
  {
    id: 1,
    name: "airbnb",
    logo: "https://cdn.worldvectorlogo.com/logos/airbnb.svg",
    sector: "",
    stage: "",
    country: "",
    investment: ""
  },
  {
    id: 2,
    name: "airbnb",
    logo: "https://cdn.worldvectorlogo.com/logos/airbnb.svg",
    sector: "",
    stage: "",
    country: "",
    investment: ""
  },
  {
    id: 3,
    name: "airbnb",
    logo: "https://cdn.worldvectorlogo.com/logos/airbnb.svg",
    sector: "",
    stage: "",
    country: "",
    investment: ""
  },
  {
    id: 4,
    name: "airbnb",
    logo: "https://cdn.worldvectorlogo.com/logos/airbnb.svg",
    sector: "",
    stage: "",
    country: "",
    investment: ""
  },
  {
    id: 4,
    name: "airbnb",
    logo: "https://cdn.worldvectorlogo.com/logos/airbnb.svg",
    sector: "",
    stage: "",
    country: "",
    investment: ""
  },
  {
    id: 5,
    name: "airbnb",
    logo: "https://cdn.worldvectorlogo.com/logos/airbnb.svg",
    sector: "",
    stage: "",
    country: "",
    investment: ""
  },
]

function paginate(array: any[], page_size: number, page_number: number) {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
}
  

export type PortfolioPageProps = {

} 




const IndexPage = () => {


  const handleLoadMore = (page: number) => { 
    /* load more items here */ 
  } 

  return (
   <div>
    <PortfolioHeader />





    <InfiniteScroll
        pageStart={0}
        loadMore={handleLoadMore}
        hasMore={true || false}
        loader={
          <Box sx={{ display: 'flex' }}>
            <CircularProgress /> 
          </Box>
        }
    >
        <ClientLogoList
          clientLogoList={portfolioData}
        />
    </InfiniteScroll>


    </div>

  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Home Page</title>
