
import React from 'react';
import { ClientLogoType } from './../../../@types'

import Box from '@mui/material/Box'; // Grid version 2



export default function ClientLogo(props :ClientLogoType) {

    const { id, name ,logo , sector, stage, country, investment } = props;

    return(
        <Box>
           <img 
             src={logo}
             alt={name}
             style={{
                height: "100%",
                width: "100%",
                margin: 20
             }}
             />
        </Box>
    )
}
